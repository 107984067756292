import React from "react";


const AppointmentStatusLogo = ({ status, label, showBgColor }) => {

    const STATUSES = {
        'not_yet_arrived': null,
        'no_show': "fas fa-eye-slash",
        'late': "fa fa-clock",
        'arrived_late': "fa fa-thumbs-up",
        'arrived': "fa fa-thumbs-up",
        'in_progress': "fa fa-hand-holding-heart",
        'is_done': "fa fa-check",
        'neutral': null
    }
    if (!status || !STATUSES[status]){
        return (<span>{label}</span>);
    }

    return (
        <span className="icon-status-container icon-container">
            <span className={`${status}${showBgColor ? '-bg-color' : '-color'} icon-status-circle`}>
                <i className={STATUSES[status]}/>
            </span>
            {label &&
                <span>{label}</span>}
        </span>
    )
}
export default AppointmentStatusLogo;